import * as React from 'react'
import SignalMiddleIcon from '../../svg/signal-middle-icon'
import * as s from './BrandButton.module.scss'
import cn from 'classnames'
import { toLink } from '../../utils'

interface IBrandButtonProps {
  title: string
  size: 'small' | 'medium' | 'large'
  link?: string
  className?: string
}

const BrandButton: React.FunctionComponent<IBrandButtonProps> = ({
  title,
  size,
  link,
  className,
}) => {
  return (
    <div
      className={cn(
        s.button,
        s['button_' + size],
        link?.length && s.button_link,
        className
      )}
      onClick={() => link && toLink(link)}
    >
      <SignalMiddleIcon className={cn(s.logo, s['logo_' + size])} />
      <span className={cn(s.button_text, s.button_text_small)}>{title}</span>
    </div>
  )
}

export default BrandButton
