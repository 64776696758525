import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { INewsItemData } from '../../types'
import * as s from './NewsItemQuote.module.scss'

interface INewsItemQuoteProps {
  data: INewsItemData['quote']
  avatar: IGatsbyImageData
}

const NewsItemQuote: React.FC<INewsItemQuoteProps> = ({ data, avatar }) => {
  const images = useStaticQuery(imagesQuery)

  return (
    <section className={s.section}>
      <div className={s.container}>
        <div className={s.content}>
          <GatsbyImage
            image={avatar}
            className={s.quoteAvatar}
            alt={data.author}
            title={data.author}
          />
          <div className={s.quoteWrapper}>
            <GatsbyImage
              image={images.quote.childImageSharp.gatsbyImageData}
              className={s.quoteImage}
              alt="Цитата"
              title="Цитата"
            />
            <div className={s.quoteHeader}>
              <p className={s.quoteAuthor} style={{ fontWeight: 700 }}>
                {data.author}
                <p className={s.quoteAuthor} style={{ fontWeight: 300 }}>
                  {data.position}
                </p>
              </p>
              <p className={s.quoteAuthorDescription}>{data.text}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewsItemQuote

const imagesQuery = graphql`
  query {
    quote: file(relativePath: { eq: "quote.png" }) {
      childImageSharp {
        gatsbyImageData(width: 154, placeholder: BLURRED)
      }
    }
  }
`
