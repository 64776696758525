import * as React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { INewsItemData } from '../../types'
import * as s from './NewsItemHeader.module.scss'

interface INewsItemHeaderProps {
  data: INewsItemData['header']
  images: Record<
    'background' | 'backgroundMobile' | 'logo' | 'logoMobile',
    IGatsbyImageData
  >
}

const NewsItemHeader: React.FC<INewsItemHeaderProps> = ({ data, images }) => {
  return (
    <section className={s.section}>
      <div className={s.backgroundWrap}>
        <GatsbyImage
          image={images.background}
          className={`${s.isHiddenMobile} ${s.background}`}
          alt={data.title}
          title={data.title}
          objectFit="fill"
        />
        <GatsbyImage
          image={images.backgroundMobile}
          className={`${s.isHiddenDesktop} ${s.background}`}
          alt={data.title}
          title={data.title}
        />
      </div>
      <div className={s.container}>
        <div className={s.content}>
          <div className={s.dateWrapper}>
            <div className={s.date}>{data.date}</div>
            <div className={s.date}>{data.tag}</div>
          </div>
          <div className={s.titleWrapper}>
            <GatsbyImage
              image={images.logoMobile}
              className={`${s.isHiddenDesktop} ${s.logo}`}
              alt={data.logoName}
              title={data.logoName}
            />
            <h1 className={s.title}>{data.title}</h1>
            <div className="flex">
              <GatsbyImage
                image={images.logo}
                className={s.isHiddenMobile}
                alt={data.logoName}
                title={data.logoName}
              />
            </div>
          </div>
          <div className={s.descriptionWrapper}>
            <p className={s.descriptionText}>{data.description}</p>
            <div className={s.descriptionServices}>
              <div style={{ marginBottom: '1rem' }}>
                <p
                  className={s.descriptionServicesText}
                  style={{ fontWeight: 400 }}
                >
                  Решения и сервисы:
                </p>
                <p className={s.descriptionServicesText}>{data.services}</p>
              </div>
              <div>
                <p
                  className={s.descriptionServicesText}
                  style={{ fontWeight: 400 }}
                >
                  Регион:
                </p>
                <p className={s.descriptionServicesText}>{data.region}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewsItemHeader
