// extracted by mini-css-extract-plugin
export var container = "NewsItemHeader-module--container---o2lM";
export var section = "NewsItemHeader-module--section--jsqQv";
export var isHiddenDesktop = "NewsItemHeader-module--isHiddenDesktop--aNt9n";
export var isHiddenMobile = "NewsItemHeader-module--isHiddenMobile--cHOGE";
export var backgroundWrap = "NewsItemHeader-module--backgroundWrap--4UdxL";
export var background = "NewsItemHeader-module--background--f6A2+";
export var content = "NewsItemHeader-module--content--PBqwH";
export var maxContent = "NewsItemHeader-module--maxContent--TmAHf";
export var title = "NewsItemHeader-module--title--f2PiX";
export var dateWrapper = "NewsItemHeader-module--dateWrapper--opIv2";
export var date = "NewsItemHeader-module--date--WxszS";
export var titleWrapper = "NewsItemHeader-module--titleWrapper--sSDEu";
export var logo = "NewsItemHeader-module--logo--aday6";
export var descriptionWrapper = "NewsItemHeader-module--descriptionWrapper--OWyHG";
export var descriptionText = "NewsItemHeader-module--descriptionText--oe5N7";
export var descriptionServices = "NewsItemHeader-module--descriptionServices--cqbJI";
export var descriptionServicesText = "NewsItemHeader-module--descriptionServicesText--t0dA7";