import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { INewsItemData } from '../../types'
import * as s from './NewsItemResult.module.scss'

interface INewsItemResultProps {
  data: INewsItemData['result']
  title: string
  image: IGatsbyImageData
}

const NewsItemResult: React.FC<INewsItemResultProps> = ({
  data,
  title,
  image,
}) => {
  const images = useStaticQuery(imagesQuery)
  return (
    <section className={s.section}>
      <div className={`${s.container} ${s.zIndex1}`}>
        <div className={s.content}>
          <GatsbyImage
            image={image}
            className={s.resultImage}
            alt={title}
            title={title}
          />
          <div className={s.resultContent}>
            <h2 className={s.resultTitle}>Результаты</h2>
            <p className={s.resultText}>{data.text}</p>
          </div>
        </div>
      </div>
      <div className={s.trapWrapper}>
        <GatsbyImage
          image={images.trap.childImageSharp.gatsbyImageData}
          className={s.trap}
          objectFit="fill"
          alt="Луч"
          title="Луч"
        />
      </div>
    </section>
  )
}

export default NewsItemResult

const imagesQuery = graphql`
  query {
    trap: file(relativePath: { eq: "trap.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
