import React, { useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslations } from '../../hooks/use-translations'
import SEO from '../../components/seo'
import * as s from '../../pages/news.module.scss'
import { INewsItemData } from '../../types'
import NewsItemHeader from '../../components/NewsItemHeader/NewsItemHeader'
import NewsItemQuote from '../../components/NewsItemQuote/NewsItemQuote'
import NewsItemResult from '../../components/NewsItemResult/NewsItemResult'
import BrandButton from '../../components/BrandButton/BrandButton'
import { GlobalContext } from '../../context/GlobalContext'
import cn from 'classnames'

const getData: INewsItemData = {
  header: {
    date: '30.11.2021',
    tag: 'Проекты',
    title:
      'Реализация цифрового взаимодействия между всеми участниками строительного проекта',
    description:
      'Как оптимизировать систему передачи данных и отчётов, связав их с использованием BIM модели на строительной площадке с помощью решений линейки SIGNAL',
    services:
      'SIGNAL DASHBOARD,\nSIGNAL INSPECTION,\nSIGNAL VALUE,\nSIGNAL TOOLS',
    region: 'Россия, Москва',
    logoName: 'Галс',
  },
  quote: {
    author: 'Иван Зуев',
    position:
      'руководитель бизнес-напраевления «Девелопмент» ГК «Галс-Девелопмент»',
    text: '«В процессе создания строительного проекта очень важна связь между партнёрами. Создавая „бесшовный“ цифровой поток между всеми участниками — генпроектировщиком, генподрядчиком и стройконтролем, мы не только видим общее состояние всех строительных площадок, но и отслеживаем все действия в режиме „онлайн“. Внедрение решения SIGNAL позволило унифицировать систему отчётов, обеспечить постоянный контроль за темпами хода строительства с договорным графиком. Система регистрирует динамику плана и факта, далее совместно с генподрядчиками происходит разбор причин возможных инцидентов для их скорейшего разрешения».',
  },
  result: {
    text: '«В процессе создания строительного проекта очень важна связь между партнёрами. Создавая „бесшовный“ цифровой поток между всеми участниками — генпроектировщиком, генподрядчиком и стройконтролем, мы не только видим общее состояние всех строительных площадок, но и отслеживаем все действия в режиме „онлайн“. Внедрение решения SIGNAL позволило унифицировать систему отчётов, обеспечить постоянный контроль за темпами хода строительства с договорным графиком. Система регистрирует динамику плана и факта, далее совместно с генподрядчиками происходит разбор причин возможных инцидентов для их скорейшего разрешения».',
  },
}

const HalsPage: React.FC = () => {
  const { isDesktop } = useContext(GlobalContext)
  const { locale } = useTranslations()
  const images = useStaticQuery(imagesQuery)

  return (
    <>
      <SEO
        title="Hals"
        description={
          locale === 'en'
            ? 'Реализация цифрового взаимодействия между всеми участниками строительного проекта'
            : 'Реализация цифрового взаимодействия между всеми участниками строительного проекта'
        }
      />

      <NewsItemHeader
        data={getData.header}
        images={{
          background: images.background.childImageSharp.gatsbyImageData,
          backgroundMobile:
            images.backgroundMobile.childImageSharp.gatsbyImageData,
          logo: images.logo.childImageSharp.gatsbyImageData,
          logoMobile: images.logoMobile.childImageSharp.gatsbyImageData,
        }}
      />
      <NewsItemQuote
        data={getData.quote}
        avatar={images.avatar.childImageSharp.gatsbyImageData}
      />
      <section className={cn(s.section, s.maxContent)}>
        <div className={s.content}>
          <h2 className={s.textTitle}>Компания и её приоритеты</h2>
          <p className={s.text}>
            ГК «Галс-Девелопмент» — один из крупнейших застройщиков Москвы.
            Компания специализируется на сегментах коммерческой и жилой
            недвижимости бизнес, премиум и элит-класса, контролирует все этапы
            жизненного цикла проекта от разработки концепции до последующего
            управления готовым объектом.
          </p>
          <p className={s.text}>
            Компания динамично развивается в том числе за счёт внедрения
            передовых технологий в производственные процессы. В настоящий момент
            объём жилой и коммерческой недвижимости в портфеле
            «Галс-Девелопмент» составляет более 2,5 млн кв. метров. Два года
            назад в компании произошёл целенаправленный переход на BIM при
            работе с проектами стадий ПД и РД. В строительном контроле
            информационные модели девелопер использует уже больше года.
          </p>
        </div>
        <div className={s.content}>
          <h2 className={s.textTitle}>Задачи цифрового развития на стройке</h2>
          <p className={s.text}>
            На стадии строительства «Галс-Девелопмент» привлекает проектные,
            производственные, экспертные и специализированные компании. Таким
            образом, глобально на стройплощадке совместно функционируют команды
            с 4 ролями: заказчик, генподрядчик, генпроектировщик и
            стройконтроль. Дополнительно на разных этапах появляются другие
            специфичные роли, связанные с производством изделий,
            узкоспециализированной экспертизой и прочие. При этом у каждой
            команды свои цели.
          </p>
          <p className={s.text}>
            Одной из главных задач заказчика при переходе на технологию BIM
            является организация бесшовной передачи информации между участниками
            проекта, так как именно на стыках зон ответственности происходит
            потеря полноты и достоверности сведений о проекте. Чтобы
            предотвратить этот процесс в компании была внедрена среда общих
            данных — система Autodesk BIM 360. К этой платформе подключились все
            участники проекта, но её функционал ограничен базовыми задачами,
            такими как хранение информации, её просмотр, обмен замечаниями и
            согласование.
          </p>
          <p className={s.text}>
            Для компании с жёстким контролем строительства на всех этапах этого
            было недостаточно, поэтому было принято решение — рассмотреть
            линейку продуктов SIGNAL.
          </p>
        </div>
      </section>
      <StaticImage
        className={cn(s.imageBlock, s.imageFullWidth)}
        layout="fullWidth"
        src="../../assets/images/news/2021_11_30_ss_hals/image-1.jpg"
        alt={`${getData.header.title} 1`}
        title={`${getData.header.title} 1`}
        placeholder="blurred"
      />
      <section className={cn(s.section, s.maxContent)}>
        <div className={s.content}>
          <div className={s.brandButtons}>
            {!isDesktop ? (
              <BrandButton size="small" title="Dashboard" className="my-4" />
            ) : (
              <BrandButton size="medium" title="Dashboard" className="my-4" />
            )}
          </div>
          <p className={s.text}>
            «В процессе создания цифрового взаимодействия между
            генпроектировщиком, генподрядчикоми стройконтролем назрела
            необходимость отображения текущего состояния дел на стройплощадках
            службам заказчика и акционерам», — отмечает Иван Зуев, руководитель
            бизнес-направления «Девелопмент» ГК «Галс-Девелопмент». «Ранее
            отчёты велись подрядчиками в виде таблицы, иногда туда включались
            необходимые схемы, планы. При этом на каждом объекте утверждение
            форм отчётов отнимало много времени и сил проектной команды
            заказчика. Мы решили максимально оптимизировать этот процесс, выбрав
            для этих целей платформу SIGNAL DASHBOARD. Она связана с технологией
            BIM 360, что позволило быстро расширить имеющийся функционал
            необходимыми элементами и сразу начать применять новое решение в
            ежедневной работе», — рассказывает Иван Зуев.
          </p>
          <p className={s.text}>
            Сначала отчёты выполнялись силами BIM-отдела «Галс-Девелопмент», но
            сейчас 80% функционала передано генподрядчикам на строящихся
            объектах компании: в деловом квартале «Невская ратуша» и
            многофункциональном спортивно-тренировочном комплексе «Академия
            спорта „Динамо“ его уже активно используют. Внедрение модуля
            позволило унифицировать систему отчётов, обеспечить широкое видение
            текущей ситуации, что в конечном итоге помогает достичь
            взаимопонимания с генподрядчиком по техническим, проектным и
            финансовым вопросам.
          </p>
          <p className={s.text}>
            При формировании отчётной документации акцент теперь делается на
            сверке темпов хода строительства с договорным графиком и
            производственной программой на текущий месяц. Также в системе
            регистрируется динамика плана/факта, и на еженедельных совещаниях с
            генподрядчиками происходит разбор причин несоответствия для их
            скорейшего решения. Теперь каждый участник проекта может гораздо
            более оперативно решать все его текущие вопросы благодаря доступу к
            цифровой информации в любом месте и в любое время. Это радикально
            повышает прозрачность всего хода строительства, а за счёт
            функционала по визуализации процессов, который также доступен в
            продукте, улучшается понимание продвижения по проекту в целом.
          </p>
          <StaticImage
            className={s.imageBlock}
            src="../../assets/images/news/2021_11_30_ss_hals/image-2.jpg"
            alt={`${getData.header.title} 2`}
            title={`${getData.header.title} 2`}
            placeholder="blurred"
          />
          <div className={s.brandButtons}>
            {!isDesktop ? (
              <BrandButton size="small" title="Value" className="my-4" />
            ) : (
              <BrandButton size="medium" title="Value" className="my-4" />
            )}
          </div>
          <p className={s.text}>
            Организация и слаженная работа среды общих данных (CDE) является
            одной из базовых задач для реализации процесса цифрового
            взаимодействия между проектированием и строительством. Пока документ
            находится в системе, все под контролем. Но после того, как его
            сохранили на жёсткий диск, передали по почте, распечатали на бумаге,
            могут появиться вопросы: итоговая ли это версия? Этот документ
            согласован всеми участниками? Все ли знают о его существовании?
          </p>
          <p className={s.text}>
            «Модуль SIGNAL VALUE позволил закрыть возникающие вопросы при помощи
            функционала простановки QR-кодов в файлах PDF — это простой и
            удобный способ для проверки и верификации рабочей документации.
            Благодаря данному модулю сведены к минимуму случаи ошибок в ходе
            строительных работ из-за неактуальной документации на руках
            подрядчиков», — отмечает Игорь Свибович, руководитель группы
            информационного моделирования ГК «Галс-Девелопмент».
          </p>
          <div className={s.brandButtons}>
            {!isDesktop ? (
              <BrandButton size="small" title="Inspection" className="my-4" />
            ) : (
              <BrandButton size="medium" title="Inspection" className="my-4" />
            )}
          </div>
          <p className={s.text}>
            В настоящий момент в компании проводится работа по внедрению отчётов
            по строительным площадкам в формате фото 360° в модуле SIGNAL
            INSPECTION. Традиционно фотофиксация, выполняемая на стройплощадке,
            загружалась бессистемно в чаты мессенджеров или хранилась на жёстком
            диске. Сразу после того, как такие фото появились, их ещё можно было
            найти и использовать, однако, по истечению короткого времени
            вероятность успешного поиска необходимой информации сводилась к
            нулю.
          </p>
          <p className={s.text}>
            С помощью технологии SIGNAL INSPECTION и функционала фото 360° ГК
            «Галс-Девелопмент» сможет обеспечить передачу информации от стадии
            строительства к стадии эксплуатации благодаря структурированному
            хранилищу изображений, выполняемых на регулярной основе из
            определённых точек строительного объекта. Кроме того, с помощью
            данного решения стало возможным в удобном для всех формате удалённо
            мониторить объём и качество выполненных работ на объекте, то есть
            следить за реальной ситуацией на строительной площадке.
          </p>
        </div>
      </section>
      <NewsItemResult
        data={getData.result}
        title={`${getData.header.title} 3`}
        image={images.result.childImageSharp.gatsbyImageData}
      />
    </>
  )
}

export default HalsPage

const imagesQuery = graphql`
  query {
    avatar: file(relativePath: { eq: "news/2021_11_30_ss_hals/avatar.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: BLURRED)
      }
    }
    background: file(
      relativePath: { eq: "news/2021_11_30_ss_hals/background.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    backgroundMobile: file(
      relativePath: { eq: "news/2021_11_30_ss_hals/background-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    result: file(relativePath: { eq: "news/2021_11_30_ss_hals/image-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1160, placeholder: BLURRED)
      }
    }
    logo: file(relativePath: { eq: "news/2021_11_30_ss_hals/logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 232, placeholder: BLURRED)
      }
    }
    logoMobile: file(
      relativePath: { eq: "news/2021_11_30_ss_hals/logo-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 81, placeholder: BLURRED)
      }
    }
  }
`
