// extracted by mini-css-extract-plugin
export var container = "NewsItemResult-module--container--kPdwi";
export var section = "NewsItemResult-module--section--6-9BK";
export var content = "NewsItemResult-module--content--1X7nD";
export var resultImage = "NewsItemResult-module--resultImage--9sjRt";
export var resultContent = "NewsItemResult-module--resultContent--ld8mw";
export var resultTitle = "NewsItemResult-module--resultTitle--Zw7XU";
export var resultText = "NewsItemResult-module--resultText--6QrtL";
export var trap = "NewsItemResult-module--trap--+MzXO";
export var trapWrapper = "NewsItemResult-module--trapWrapper--5bJqC";
export var zIndex1 = "NewsItemResult-module--zIndex1--AwSRW";