// extracted by mini-css-extract-plugin
export var button = "BrandButton-module--button--EprK9";
export var button_small = "BrandButton-module--button_small--zj1qj";
export var button_large = "BrandButton-module--button_large--SuGKz";
export var button_text = "BrandButton-module--button_text--CMkp8";
export var button_text_small = "BrandButton-module--button_text_small--zreLY";
export var button_text_large = "BrandButton-module--button_text_large--2Q9hV";
export var button_link = "BrandButton-module--button_link--iui7L";
export var logo = "BrandButton-module--logo--NsGbw";
export var logo_small = "BrandButton-module--logo_small--2gfhU";
export var logo_medium = "BrandButton-module--logo_medium--zwhno";
export var logo_large = "BrandButton-module--logo_large--W+YUy";